* {
  box-sizing: border-box;
}

.overlay {
  z-index: 1;
  background-color: transparent;
}


.btn {
  display: inline-block;
  height: 35px;
  width: 65px;

}

.buttons {
    position: fixed;
    border-style: solid;
    top: 50px;
    border-width: 1px;
    border-color: grey;
    border-radius: 15px;
    background-color: white;
}

.savemenu {
  position: fixed;
  border-style: solid;
  text-align: center;
  left: 9px; /*47px;*/
  line-height: 35px;
  border-width: 1px;
  border-color: grey;
  border-radius: 15px;
  background-color: white;
}

.buttonsmenu {
  position: fixed;
  border-style: solid;
  text-align: center;
  top: 25px;
  /*width: 65px;*/
  left: 47px;
  line-height: 35px;
  border-width: 1px;
  border-color: grey;
  border-radius: 15px;
  background-color: white;
}

.btn.clear {
  text-align: center;
  line-height: 35px;
  color: grey;

}

.btn.pdf {
  text-align: center;
  line-height: 35px;
  color: grey;
}

.btn.database {
  text-align: center;
  line-height: 35px;
  color: grey;
}

.btn.share {
  text-align: center;
  line-height: 35px;
  color: grey;
}

.btn.session {
  text-align: center;
  line-height: 35px;
  color: grey;
}

.btn.control {
  color: grey;
  text-align: center;
  line-height: 35px;
}

.btn.control:selection {
  border-style: solid;
  background-color: black;
  color: grey;
  text-align: center;
}

.btn.control:-moz-selection {
  border-style: solid;
  background-color: black;
  color: grey;
  text-align: center;
}




/*@media (max-width: 768px) {*/
/*@media (max-width: 790px) {
  .buttonsmenu {
    position: fixed;
    border-style: solid;
    top: 50px;
    width: 200px;
    height: 37px;
    border-width: 1px;
    border-color: grey;
    border-radius: 15px;
    background-color: white;
  }

  .buttons {
    display: none;
  }
}*/
