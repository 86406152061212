* {
  box-sizing: border-box;
}

.whiteboard {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.overlay {
  z-index: 1;
  background-color: transparent;
}

.control-panel-overlay {
  z-index: 1000;
  background-color: transparent;
}

.colors {
  position: fixed;
  top: 100px;
}

.color {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-style: solid;
  border-radius: 43px;
  border-color: grey;
}

.color.black { background-color: black; }
.color.red { background-color: red; }
.color.green { background-color: green; }
.color.blue { background-color: blue; }
.color.yellow { background-color: yellow; }
.color.white { background-color: white; }
.color.orange { background-color: orange; }
.color.gray { background-color: gray; }
.color.pink { background-color: pink; }
.color.purple { background-color: purple; }
.color.brown { background-color: brown; }

.btn {
  display: inline-block;
  height: 35px;
  width: 65px;

}

.buttons {
    position: fixed;
    border-style: solid;
    top: 50px;
    border-width: 1px;
    border-color: grey;
    border-radius: 15px;
    background-color: white;
}

.sessionurl {
    position: fixed;
    display: inline-block;
    border-style: solid;
    border-width: 1px;
    border-color: grey;
    border-radius: 15px;
    background-color: white;
}

.camqrlink {
    position: fixed;
    border-style: solid;
    top: 10px;
    right: 10px;
    height: 130px;
    with: 130px;
    border-width: 1px;
    border-color: grey;
    border-radius: 12px;
}

.btn.clear {
  text-align: center;
  line-height: 35px;
  color: grey;

}

.btn.pdf {
  text-align: center;
  line-height: 35px;
  color: grey;
}

.btn.download {
  text-align: center;
  line-height: 35px;
  color: grey;
}

.btn.upload {
  text-align: center;
  line-height: 35px;
  color: grey;
}

.btn.session {
  text-align: center;
  line-height: 35px;
  color: grey;
}

.btn.control {
  color: grey;
  text-align: center;
  line-height: 35px;
}

.btn.control:selection {
  border-style: solid;
  background-color: black;
  color: grey;
  text-align: center;
}

.btn.control:-moz-selection {
  border-style: solid;
  background-color: black;
  color: grey;
  text-align: center;
}

.btn.selectmove {
  text-align: center;
  line-height: 34px;
  color: grey;
}

.btn.selectedit {
  text-align: center;
  line-height: 34px;
  color: grey;
}

.text-tools{
  border-style: solid;
  border-color : grey;
  border-radius: 15px;
  width: 320px;
  height: 35px;
}

.select-tools{
  border-style: solid;
  border-color : grey;
  border-radius: 15px;
  width: 133px;
  height: 35px;
}
