* {
  box-sizing: border-box;
}

.notificationbar {
  position: fixed;
  border-style: solid;
  top: 0px;
  left: 0px;
  right: 10px;
  width: 100px; /*100vw;*/
  height: 35px;
  border-width: 1px;
  border-color: red;
  background-color: #FFB2B2; /*#FFB2B2;*/ /*#FF9C9C;*/
  color: grey;
  /*text-align: center;*/
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */

  /*border-radius: 15px;*/
}
