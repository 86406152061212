* {
  box-sizing: border-box;
}

.overlay-payment {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.payment-modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  border-color: grey;
  border-style: solid;
  text-align: center;
}

.payment-modal button {
  margin: 10px;
  padding: 10px 20px;
  border-radius: 26px;
  border-width: thin;
  border-color: grey;
}
