* {
  box-sizing: border-box;
}

.ct-tooltip {
  vertical-align: middle;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: grey;
  color: grey;
}
