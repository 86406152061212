* {
  box-sizing: border-box;
}

.whiteboard {
 width: 100vw; /* 100% of the viewport width */
 height: 100vh;
 position: absolute;
 overflow: hidden;
 box-sizing: border-box; /* Include padding and border in the element's total width and height */
 background-color: white; /* Just for visualization, you can remove or replace it */
}

.overlay {
  z-index: 1;
  background-color: transparent;
}

.colors {
  position: fixed;
  top: 100px;
}

.color {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-style: solid;
  border-radius: 43px;
  border-color: grey;
}

.color.black { background-color: black; }
.color.red { background-color: red; }
.color.green { background-color: green; }
.color.blue { background-color: blue; }
.color.yellow { background-color: yellow; }
.color.white { background-color: white; }
.color.orange { background-color: orange; }
.color.gray { background-color: gray; }
.color.pink { background-color: pink; }
.color.purple { background-color: purple; }
.color.brown { background-color: brown; }


/*@media (max-width: 790px) {
  .whiteboard {
    width: 100vw;
    height: calc(100vh - 50px);
    position: absolute;
    top: 50px;
    box-sizing: border-box;
    background-color: white;
    border-top: 1px dashed grey;
  }
}*/
