* {
  box-sizing: border-box;
}

.overlay {
  z-index: 1;
  background-color: transparent;
}

.sessionurl {
    position: fixed;
    /*top: 45px;*/ /*50px;*/
    left: 10px;
    display: inline-block;
    border-style: solid;
    border-width: 1px;
    border-color: grey;
    border-radius: 15px;
    background-color: white;
}

/*@media (max-width: 768px) {*/
@media (max-width: 950px) {
  .sessionurl {
    display: none;
  }
}
